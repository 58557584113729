
/* @ngInject */
export default class DeactivateModalController {
    constructor(DialogService, ToastService, device, SensorService, successfullyDeactivated) {
        this.DialogService = DialogService
        this.ToastService = ToastService
        this.device = device
        this.SensorService = SensorService
        this.successfullyDeactivated = successfullyDeactivated.bind(this)
    }

    $onInit() {
        this.deactivating = false
    }

    deactivate() {
        this.deactivating = true
        this.SensorService.deactivateDevice(this.device.id).then(() => {
            this.ToastService.showSimpleText('Sensor has been deactivated')
            this.successfullyDeactivated()
            this.closeModal()
        }).catch((error) => {
            const errorMessage = error.data ? error.data.error : 'An error occurred'
            this.ToastService.showSimpleText(errorMessage)
            this.deactivating = false
        })
    }

    closeModal() {
        this.DialogService.cancel();
    }
}
